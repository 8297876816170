import React, { ComponentType } from 'react'
import { Location, RouteComponentProps } from '@reach/router'

const browser = typeof window !== 'undefined' && window

const RedirectPage: ComponentType<RouteComponentProps> = () =>
  browser ? (
    <Location>
      {({ location }) => {
        window.location.replace(`/redirect?q=${location.pathname}`)
        return null
      }}
    </Location>
  ) : null

export default RedirectPage
